import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";


import App from "./App";

Sentry.init({
  dsn: "https://2fe52c9f141541228d0941c04f03cd7c@o562584.ingest.sentry.io/6127650",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});


ReactDOM.render(
  <>
      <App />
  </>,
  document.getElementById("root")
);
