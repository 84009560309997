import React, { lazy, Suspense } from "react";
import { CssBaseline, MuiThemeProvider } from "@material-ui/core";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { LinearProgress } from "@material-ui/core";
import propTypes from "prop-types";
import { theme } from "./styles/global";
import "./config/ReactotronConfig";
import { SnackbarProvider } from 'notistack';
const Main = lazy(() => import("./pages/Main"));
const Adesao = lazy(() => import("./pages/Adesao"));

const App = () => (
  <MuiThemeProvider theme={theme}>
    <SnackbarProvider maxSnack={3}>
      <CssBaseline />
      <Suspense fallback={<LinearProgress />}>
        <BrowserRouter>
          <Switch>
            <Route path="/adesao" component={Adesao} />
            <Route path="/via" render={(props) => <Main {...props} via={true} />} />
            {/* wp - whatsapp */}
            <Route path="/" render={(props) => <Main {...props} invoiceWhatsapp={true} />} />
            <Route path="/acordo" render={(props) => <Main {...props} invoiceOrAgreement={true}  />} />
            <Route exact={true} path="/" component={Main} />
            <Route component={Main} />
          </Switch>
        </BrowserRouter>
      </Suspense>
    </SnackbarProvider>
  </MuiThemeProvider>
);

App.propTypes = {
  location: propTypes.object
};

export default App;
