import { css } from 'styled-components';
import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00582B',
    },
    secondary: {
      main: '#797979',
    },
    default: {
      main: '#FFFFFF',
    },
  },
  typography: {
    useNextVariants: true,
    "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
  }
});

export const media = {
  handheld: (...args) => css`
    @media (max-width: 943px) {
      ${css(...args)};
    }
  `,
  desktop: (...args) => css`
    @media (min-width: 943px) {
      ${css(...args)};
    }
  `,
};
